<template>
  <b-card v-if="empleadoData && departamentos">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">
        Matrícula Nº: {{ empleadoData.matricula }}
      </h4>
    </div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit }"
    >
      <b-form
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <!-- Field: Nombre -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Form: Personal Info Form -->
            <div class="d-flex mt-2 mb-0">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Información personal
              </h4>
            </div>
            <validation-provider
              #default="validationContext"
              name="Nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre"
                label-for="nombre"
                class="mt-1"
              >
                <b-form-input
                  v-model="empleadoData.nombre"
                  class="form-control"
                  placeholder="John"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Field: Apellidos -->
            <validation-provider
              #default="validationContext"
              name="Apellidos"
              rules="required"
            >
              <b-form-group
                label="Apellidos"
                label-for="apellidos"
              >
                <b-form-input
                  id="apellidos"
                  v-model="empleadoData.apellidos"
                  placeholder="Doe"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-row>
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <!-- Field: Telefono -->
                <validation-provider
                  #default="validationContext"
                  name="Telefono"
                >
                  <b-form-group
                    label="Telefono"
                    label-for="telefono"
                  >
                    <b-form-input
                      id="telefono"
                      v-model="empleadoData.telefono"
                      placeholder="12346789"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <!-- Field: Movil -->
                <validation-provider
                  #default="validationContext"
                  name="Movil"
                >
                  <b-form-group
                    label="Movil"
                    label-for="movil"
                  >
                    <b-form-input
                      id="movil"
                      v-model="empleadoData.movil"
                      placeholder="123456789"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Field: Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="empleadoData.email"
                  placeholder="johndoe@email.com"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Header: Address -->
            <div class="d-flex mt-2">
              <feather-icon
                icon="MapPinIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Dirección
              </h4>
            </div>

            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">
              <!-- Field: Direccion-->
              <b-col
                cols="12"
                md="8"
                lg="8"
              >
                <validation-provider
                  #default="validationContext"
                  name="Direccion"
                  rules="required"
                >
                  <b-form-group
                    label="Direccion"
                    label-for="direccion"
                  >
                    <b-form-input
                      id="direccion"
                      v-model="empleadoData.direccion"
                      placeholder="Direccion"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Poblacion -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Poblacion"
                  rules="required"
                >
                  <b-form-group
                    label="Poblacion"
                    label-for="poblacion"
                  >
                    <b-form-input
                      id="poblacion"
                      v-model="empleadoData.poblacion"
                      placeholder="Poblacion"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
                md="4"
                lg="4"
              >
                <!-- Field: DNI -->
                <validation-provider
                  #default="validationContext"
                  name="DNI"
                >
                  <b-form-group
                    label="DNI"
                    label-for="dni"
                  >
                    <b-form-input
                      id="dni"
                      v-model="empleadoData.dni"
                      placeholder="123456"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
                md="4"
                lg="4"
              >
                <!-- Field: Nie -->
                <validation-provider
                  #default="validationContext"
                  name="Nie"
                >
                  <b-form-group
                    label="NIE"
                    label-for="nie"
                  >
                    <b-form-input
                      id="nie"
                      v-model="empleadoData.nie"
                      placeholder="NIE"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
                md="4"
                lg="4"
              >
                <b-form-group
                  label="Nº Tarjeta Tickaje"
                  label-for="tarjeta_tickaje"
                >
                  <b-form-input
                    id="tarjeta_tickaje"
                    v-model="empleadoData.tarjeta_tickaje"
                    placeholder="Nº Tarjeta Tickaje"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-col>
              <b-row>
                <b-col class="pl-0">
                  <!-- Field: Carnet -->
                  <validation-provider
                    #default="validationContext"
                    name="Carnet"
                  >
                    <b-form-group
                      label="Carnet"
                      label-for="carnet"
                    >
                      <b-form-input
                        id="carnet"
                        v-model="empleadoData.carnet"
                        placeholder="Carnet"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Vehículo"
                    label-for="vehiculo"
                  >
                    <b-form-input
                      id="vehiculo"
                      v-model="empleadoData.vehiculo"
                      placeholder="Vehículo asignado"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="pr-0">
                  <b-form-group
                    label="Matrícula"
                    label-for="matricula"
                  >
                    <b-form-input
                      id="matricula"
                      v-model="empleadoData.vehiculo_matricula"
                      placeholder="Matrícula del vehículo asignado"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <!-- Fields: has_uniform -->
                <p
                  class="mr-1"
                  style="font-weight: bold"
                >
                  ¿Tienes uniforme?
                </p>
                <b-form-checkbox
                  v-model="empleadoData.has_uniform"
                  name="check-button"
                  switch
                >
                  <span style="font-weight: 500">{{
                    empleadoData.has_uniform ? "Sí" : "No"
                  }}</span>
                </b-form-checkbox>
              </b-row>
              <b-row>
                <!-- Fields: estado -->
                <p
                  class="mr-1"
                  style="font-weight: bold"
                >
                  ¿Es un empleado activo?
                </p>
                <b-form-checkbox
                  v-model="empleadoData.estado"
                  name="check-button"
                  switch
                >
                  <span style="font-weight: 500">{{
                    empleadoData.estado ? "Sí" : "No"
                  }}</span>
                </b-form-checkbox>
              </b-row>

              <!-- Fields: Is_User -->
              <b-row>
                <p
                  class="mr-1"
                  style="font-weight: bold"
                >
                  ¿Es un usuario del sistema?
                </p>
                <b-form-checkbox
                  v-model="empleadoData.is_user"
                  name="check-button"
                  switch
                  :disabled="
                    empleadoData.is_user ? true : showInvalidToBeUserMsg
                  "
                  @change="testUserValidation"
                >
                  <span
                    style="font-weight: 500"
                    class="pr-1"
                  >{{
                    empleadoData.is_user ? "Sí" : "No"
                  }}</span>
                </b-form-checkbox>
                <strong
                  v-if="showInvalidToBeUserMsg"
                  style="color: red"
                >Se requiere el correo electrónico del empleado para
                  convertirlo en usuario</strong>
              </b-row>
            </b-col>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Header: Contract -->
            <div class="d-flex mt-2">
              <feather-icon
                icon="BookOpenIcon"
                size="19"
              />
              <h4 class="mb-1 ml-50">
                Información del contrato
              </h4>
            </div>

            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <label class="mb-0"> Inicio Contrato </label>
                <div class="d-flex align-items-center position-relative">
                  <feather-icon
                    icon="CalendarIcon"
                    size="20"
                    class="position-absolute"
                    style="right: 10px"
                  />
                  <flat-pickr
                    v-model="empleadoData.inicio_contrato"
                    placeholder="dd-mm-yyyy"
                    class="form-control"
                    :config="{
                      maxDate: empleadoData.fin_contrato,
                      format: 'd-m-Y',
                      altFormat: 'd-m-Y',
                      allowInput: true,
                      altInput: true,
                      locale: Spanish,
                    }"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <label class="mb-0"> Fin Contrato </label>
                <div class="d-flex align-items-center position-relative">
                  <feather-icon
                    icon="CalendarIcon"
                    size="20"
                    class="position-absolute"
                    style="right: 10px"
                  />
                  <flat-pickr
                    v-model="empleadoData.fin_contrato"
                    placeholder="dd-mm-yyyy"
                    class="form-control"
                    :config="{
                      minDate: empleadoData.inicio_contrato,
                      format: 'd-m-Y',
                      altFormat: 'd-m-Y',
                      allowInput: true,
                      altInput: true,
                      locale: Spanish,
                    }"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <label class="mb-0 mt-2"> Departamento </label>
                <v-select
                  v-model="empleadoData.fk_dpto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departamentos"
                  :reduce="departamento => departamento.value"
                  :clearable="false"
                  placeholder="Selecionar Departamento"
                  class="mb-1"
                />
              </b-col>
              <!-- Field: Tipo Contrato -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Tipo Contrato"
                >
                  <b-form-group
                    label="Tipo de Contrato"
                    label-for="Tipo Contrato"
                  >
                    <v-select
                      v-model="empleadoData.tipo_contrato"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="avaiableContratos"
                      :clearable="false"
                      placeholder="Tipo Contrato"
                      class="mb-1"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Horario -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <!-- Field: Horario -->
                <b-form-group
                  label="Horario"
                  label-for="horario"
                >
                  <b-form-input
                    id="horario"
                    v-model="empleadoData.horario"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Movil -->
            <validation-provider
              #default="validationContext"
              name="Telefono Oficina"
            >
              <b-form-group
                label="Telefono Oficina"
                label-for="tlf_oficina"
              >
                <b-form-input
                  id="tlf_oficina"
                  v-model="empleadoData.tlf_oficina"
                  placeholder="123456789"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr style="border-top: 1px solid gainsboro">
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Guardar cambios
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'empleados-list' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import departamentoStoreModule from '@/views/system/departamento/departamentoStoreModule'
import useDepartamentosList from '@/views/system/departamento/useDepartamentosList'
import empleadoStoreModule from './empleadoStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    flatPickr,
    vSelect,
  },

  data() {
    return {
      Spanish,
      required,
      alphaNum,
      email,
      avaiableContratos: ['Jornada Completa', 'Jornada Parcial', 'Otro'],
      showInvalidToBeUserMsg: false,
    }
  },
  computed: {
    invalidToBeUser() {
      if (!this.empleadoData.email) {
        return true
      }
      return false
    },
  },

  watch: {
    empleadoData: {
      handler(data) {
        if (data.email) {
          this.showInvalidToBeUserMsg = false
        }
      },
      deep: true,
    },
  },

  methods: {
    testUserValidation() {
      if (this.invalidToBeUser) {
        this.showInvalidToBeUserMsg = true
        this.empleadoData.is_user = false
      } else this.showInvalidToBeUserMsg = false
    },
    onSubmit() {
      this.empleadoData.fin_contrato = this.empleadoData.fin_contrato
        ? this.empleadoData.fin_contrato
        : '2050-01-01'
      this.$store
        .dispatch('app-empleado/addEmpleado', this.empleadoData)
        .then(() => {
          this.$router.push({ name: 'empleados-list' })
        })
    },
  },

  setup() {
    const blankEmpleadoData = {
      nombre: '',
      apellidos: '',
      direccion: '',
      poblacion: '',
      nie: '',
      tipo_contrato: '',
      horario: '',
      dni: '',
      carnet: '',
      telefono: '',
      movil: '',
      is_user: false,
      estado: false,
      inicio_contracto: null,
      fin_contracto: null,
      fk_dpto: null,
      tlf_oficina: '',
    }
    const empleadoData = ref(null)
    const departamentos = ref(null)

    const DEPARTAMENTO_APP_STORE_MODULE_NAME = 'app-departamento'
    const EMPLEADO_APP_STORE_MODULE_NAME = 'app-empleado'

    const resetEmpleadoData = () => {
      empleadoData.value = JSON.parse(JSON.stringify(blankEmpleadoData))
    }

    // Register module
    if (!store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.registerModule(EMPLEADO_APP_STORE_MODULE_NAME, empleadoStoreModule)

    if (!store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        DEPARTAMENTO_APP_STORE_MODULE_NAME,
        departamentoStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLEADO_APP_STORE_MODULE_NAME)

      if (store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)
    })

    const { listDepartamentos } = useDepartamentosList()

    listDepartamentos().then(response => {
      departamentos.value = response
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-empleado/fetchEmpleado', router.currentRoute.params.id)
        .then(response => {
          empleadoData.value = response
          empleadoData.value.fk_dpto = departamentos.value.find(
            el => el.key === empleadoData.value.fk_dpto,
          )
        })
        .catch(error => {
          if (error.response.status === 404) {
            empleadoData.value = undefined
          }
        })
    } else {
      empleadoData.value = blankEmpleadoData
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetEmpleadoData)

    return {
      empleadoData,
      departamentos,
      refFormObserver,
      getValidationState,
      resetForm,
      listDepartamentos,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
